*{
  margin: 0;
  font-family: 'FiraSans';

}

#profilePic{
  border-radius: 50%;  
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;

  
}

@media only screen and (max-width: 1550px) {



  
}

#experienceImage{
  width: 450px;
  height: 450px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;


 
}



#blue{
  color: #B0E0E6;
  font-size: 35px;
  
  text-decoration: underline;
}

nav{
  font-size: 2rem;
  color: #B0E0E6;
  text-align: center;
}


img{
 margin-top: 5%;

  
}

#descriptionOfExperience{
  margin-top: 5%;
  font-size: 1.2rem;
  color: #B0E0E6;
 
  text-align: center;
}


form{

  place-items: center;
}

.inputs{
  margin: auto;
  text-align: center;
  display: block;
  margin-top: 2%;
}

#message{
 
  text-align: left;
  padding-left:0;
  padding-top:0;
  padding-bottom:0.4em;
  padding-right: 0.4em;
   width: 500px;
  height: 300px;
  resize: none;
}

#inputs{
  text-align: left;
  padding-left:0;
  padding-top:0;
  padding-bottom:0.4em;
  padding-right: 0.4em;
   width: 500px;
  height: 15px;
  resize: none;
}

button{
  background-color:lightblue;
  border: none;
  padding: 20px 100px;
  font-size: 20px;
  border-radius: 50%;
  outline: none;

}

button:hover{
  background-color:darkcyan;
 
}

a:hover{
    text-decoration: underline;
    color: #0000FF
}

#socialMedia{
  margin: 1%;

}

html
{
    min-height:100%;    
}

html, body
{
    
    background: black;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
   
    
}


@media only screen and (min-width: 768px) {
  /* tablets and desktop */
}

@media only screen and (max-width: 1100px) and (min-width: 701px) {
  #experienceImage{
    width: 300px;
  height: 300px;

  
  }
  
}

@media only screen and (max-width: 1500px) and (min-width: 1101px) {
  #experienceImage{
    width: 300px;
  height: 300px;



  }
  
}



@media only screen and (max-width: 700px) {
  /* portrait phones */

textarea{
  width: 50px;
  height: 50px;
}
 
#message{
 

   width: 80%;
  

}

#inputs{

  width: 80%;

 
}

 button{
  font-size: 10px;
  padding: 15px 32px;
}




a{
  font-size: 0.9rem;
}
 



#title{
  
font-size: 2rem;
justify-content: center;
text-align:center;

}

#date{
  font-size: 1.5rem;
  justify-content: center;
  text-align:center;
}


@media only screen and (max-width: 1500px) {
       

  #descriptionOfExperience{
    margin-top: 1%;
    font-size: 1rem;
text-align:center;
justify-content: center;

  }

}

@media only screen and (max-width: 1500px) and (orientation: portrait) {
 
  nav{
    text-align: center;
    font-size: 1.5rem;
    margin-right:auto;
margin-left:auto;

    }

  #descriptionOfExperience{
    margin-top: 1%;
    font-size: 1rem;
text-align:center;
justify-content: center;

  }

  
}
}

#description{

  font-size: 1.1rem;
}

@media only screen and (max-width: 1100px){

  #experienceImage{
    width: 200px;
  height: 200px;
  
  
  }
  

}
@media only screen and (max-width: 1100px) {
   
#re{
  margin: 5px;
  align-content: center;
}

}

@media only screen and (max-width: 1100px) and (orientation: portrait) {
   
  #re{
  margin: 5px;
  align-content: center;
  }

}


@media only screen and (max-width: 800px) {
       
 

  #experienceImage{
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;


   
  }
  

}

